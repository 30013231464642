<div class="container-fluid">

  <app-head></app-head>

  <div class="row">
    <table>
      <tr>
        <td style="height: 12vh;">
        </td>
      </tr>
      <tr>
        <td>
        </td>
      </tr>
    </table>
  </div>

  <div class="row">
    <table>
      <tr>
        <td>
        </td>
      </tr>
      <tr>
        <td>
        </td>
      </tr>
    </table>
  </div>

  <div class="row">
    <div class="col">
      <br>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">首頁</a></li>
          <li class="breadcrumb-item"><a href="#">學潛水</a></li>
          <li class="breadcrumb-item active" aria-current="page">開放水域潛水員</li>
        </ol>
      </nav>
      <br>
    </div>
  </div>

  <div class="row">
    <div class="col-xl">
    </div>
    <div class="col-xl-10">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>假日班</a>
          <ng-template ngbNavContent class="custom-class">
            <br>
            <h3 style="font-family:DFKai-sb;">Open Water Diver 假日班</h3>
            <hr>
            <!-- 假日班 課程內容 -->
            <div>
              <h3 style="font-family:DFKai-sb;">課程內容</h3>
              <ul>
                <li class="fs-6">
                  學科（9hr）<br>
                  潛水物理學／潛水生理學／裝備介紹／海洋環境／生態介紹／潛伴價值／潛水流程
                </li>
                <li class="fs-6">
                  泳池訓練（4hr）<br>
                  裝備調整／潛水技巧練習／狀況模擬／障礙排除
                </li>
                <li class="fs-6">
                  海洋訓練（12hr）<br>
                  技巧複習／狀況模擬及障礙排除／方向辨認／潛水日誌記錄
                </li>
              </ul>
              <br>
              <img src="../../../../assets/Image/Data/OWD_Marketing_Area_01.jpg" width="70%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
            </div>
            <!-- 假日班 課程特色 -->
            <div>
              <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
              <ul class="fs-6">
                <li>
                  SSI數位教材搭配MySSI APP，課前預習與紀錄潛水日誌好方便。
                </li>
                <li>
                  合格的領導級人員與學員比例2：3~5（不含移地結訓部分）。
                </li>
                <li>
                  超值贈品方案，合身且全新的裝備好衛生，日後潛水省下輕裝租借費。
                </li>
                <li>
                  海洋課程期間提供電腦錶使用，讓潛水過程安全又方便。
                </li>
                <li>
                  課程結訓即日起三個月內，參加東北角岸潛活動享有迎新優惠價，每場次含裝備租借最低價1300元。
                </li>
              </ul>
              <br>
              <img src="../../../../assets/Image/Data/OWD_Marketing_Area_02.jpg" width="40%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
            </div>
            <!-- 假日班 課程規劃 -->
            <div>
              <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td width="10%">
                    </td>
                    <td>
                      <p class="fs-6">
                        DAY 1 | 學科 19:00~22:00<br>
                      </p>
                      <hr>
                      <p class="fs-6">
                        潛水物理學<br>
                        潛水生理學<br>
                        贈品套量
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    </td>
                    <td>
                      <p class="fs-6">
                        <br>
                        DAY 2 | 學科+泳池/平靜水域 08:00~18:00<br>
                      </p>
                      <hr>
                      <p class="fs-6">
                        海洋生物與環境<br>
                        裝備組裝與下水前檢查<br>
                        潛水技巧練習<br>
                        狀況模擬及障礙排除
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    </td>
                    <td>
                      <p class="fs-6">
                        <br>DAY 3 | 平靜/開放水域 08:00~15:00<br>
                      </p>
                      <hr>
                      <p class="fs-6">
                        潛水技巧練習<br>
                        水中障礙排除<br>
                        裝備清洗
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    </td>
                    <td>
                      <p class="fs-6">
                        <br>DAY 4 | 開放水域 08:00~18:00<br>
                      </p>
                      <hr>
                      <p class="fs-6">
                        潛水技巧複習<br>
                        狀況模擬及障礙排除<br>
                        水中方向辨認<br>
                        共生練習<br>
                        潛水日誌記錄<br>
                        學科考試
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    </td>
                    <td>
                      <p class="fs-6">
                        <br>DAY 5 | 開放水域 08:00~15:00<br>
                      </p>
                      <hr>
                      <p class="fs-6">
                        Fun Dive體驗
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="col">
              </div>
              <div class="col-xl-10">
                <br>
                <div id="carouselExampleIndicators" class="carousel slide">
                  <div class="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  </div>
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img src="../../../../assets/Image/Data/OWD_Weekend_Schedule01.png" class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item">
                      <img src="../../../../assets/Image/Data/OWD_Weekend_Schedule.png" class="d-block w-100" alt="...">
                    </div> 
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
                <br>
              </div>
              <div class="col">
              </div>
            </div>
            <!-- 假日班 課程學費 -->
            <!-- 最新優惠 -->
            <div><!-- 
              <br>
              <br>
              <h3 style="font-family:DFKai-sb;">最新優惠</h3>
              <hr>
              <br>
              <div class="d-block grey lighten-3">
                <table borderless="true">
                  <tbody>
                    <tr>
                      <td colspan="3" class="fs-6">
                        <p>
                          最新優惠<br>
                          🌞夏季涼一夏優惠方案🌞<br>
                          現在報名假日班15-19期/平日班12-15期課程<br>
                          🎉優惠2500元（不得與其他方案並用）<br>
                          <br>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
            </div>
            <!-- 方案一  -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">課程學費</h3>
              <hr>
              <br>
              <p class="fs-6" style="color: green;">定價 19,800 元（含贈品）</p>
              <ul>
                <li>現金優惠，課程尾款付現金，可折扣 300 元。</li>
                <li>費用包含：輕裝組、SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（浮力調整裝置、調節器、電腦錶）、水域遊憩責任保險。</li>
                <li>費用不含：餐費、海洋實習之交通、住宿費、浮潛裝備租借450元/天。</li>
                <li>
                  自備輕裝 ( 不要贈品 ) 可折扣 3000 元，但自備之裝備需經教練確認安全無虞，才可在課程中使用！<br>
                  註：以上優惠只要符合條件，可合併使用
                </li>
              </ul>
              <p class="fs-6" style="color: red;">團結賺優惠!</p>
              <ul>
                <li>
                  兩人成行，每人折扣 700 元<br>
                  三人同樂，每人折扣 1200 元<br>
                  四五成群，每人折扣 1700 元<br>
                  六人以上請來電洽談。
                </li>
              </ul>
              <a class="fs-6" style="color: red;">老同學秀臉有優惠!</a>
              <ul>
                <li>iDiving 的 Freediver/人魚學員，可再享有1000 元折扣。</li>
              </ul>
              <img src="../../../assets/Image/Data/OWD_Giveaway.png" width="100%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
              <ul>
                <li>
                  贈品:<br>
                  全套總價14,000元。<br>
                  面鏡、呼吸管、防寒衣、潛水手套、套鞋、蛙鞋、網袋
                </li>
                <li>
                  贈品樣式及規格尺寸以實品為準。
                </li>
                <li>
                  如欲指定其他款式可補價差更換，建議課前告知以便上課使用。
                </li>
              </ul>
            </div>
            <!-- 假日班 報名方式 -->
            <div>
              <br>
              <app-paymentmethod></app-paymentmethod>
              <br>
            </div>
            <!-- 假日班 考核標準 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">考核標準</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" colspan="2">
                      <p>學員結訓均需達到學科與術科課程標準之評鑑</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" width="50px" style="vertical-align:text-top;">
                      <p>學科:</p>
                    </td>
                    <td class="fs-6">
                      <p>需通過筆試測驗（成績達80分以上），未達標準需於檢討後重考。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <p>術科:</p>
                    </td>
                    <td class="fs-6">
                      <p>平靜水域所學之潛水技巧須在開放水域操作，並接受教練評鑑。</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 假日班 上課地點 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">上課地點</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" colspan="2">
                      <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" width="80px" style="vertical-align:text-top;">
                      <p>教室學科:</p>
                    </td>
                    <td class="fs-6">
                      <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <p>海洋實習:</p>
                    </td>
                    <td class="fs-6">
                      <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <p>註: </p>
                    </td>
                    <td class="fs-6">
                      <p>
                        上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），若有額外增加的場地費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 假日班 特約課程 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">特約課程</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>學科部份，每另外約一日課程加價 300 元</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>術科部份，每另外約一日課程加價 500 元</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 假日班 更改辦法 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">更改辦法</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>課程須於開始上課後的兩個月內完成。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>上課日期之更改須在 10 天前告知</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>假日班學生可改於平日班完成術科，無額外優惠，亦不須額外付費。</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 假日班 取消辦法 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">取消辦法</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下:</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                    </td>
                    <td class="fs-6">
                      <p>
                        1. NT 3,000 元訂金用做購買線上教材，無法退費。<br>
                        2. 開課前 30 天以上，退回全新贈品，可退費NT 12,000元。<br>
                        3. 開課前 30 天之內，退回全新贈品，可退費NT 10,000元。<br>
                        4. 已上過學科，退回全新贈品，可退費NT 8,000元。<br>
                        5 .已上過泳池課程或贈品已使用，可退費NT 4,000元。<br>
                        6. 已上過海洋實習，恕不退費。
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>平日班</a>
          <ng-template ngbNavContent>
            <br>
            <h3 style="font-family:DFKai-sb;">Open Water Diver 平日班</h3>
            <hr>
            <!-- 平日班 課程介紹 -->
            <div>
              <h3 style="font-family:DFKai-sb;">課程內容</h3>
              <ul>
                <li class="fs-6">
                  學科（9hr）<br>
                  潛水物理學／潛水生理學／裝備介紹／海洋環境／生態介紹／潛伴價值／潛水流程
                </li>
                <li class="fs-6">
                  泳池訓練（4hr）<br>
                  裝備調整／潛水技巧練習／狀況模擬／障礙排除
                </li>
                <li class="fs-6">
                  海洋訓練（12hr）<br>
                  技巧複習／狀況模擬及障礙排除／方向辨認／潛水日誌記錄
                </li>
              </ul>
              <br>
              <img src="../../../../assets/Image/Data/OWD_Marketing_Area_01.jpg" width="100%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
            </div>
            <!-- 平日班 課程特色 -->
            <div>
              <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
              <ul class="fs-6">
                <li>
                  SSI數位教材搭配MySSI APP，課前預習、課後潛水日誌記錄，精確掌握學習動態。
                </li>
                <li>
                  合格的領導級教練與學員比例1：2~3。
                </li>
                <li>
                  海洋課程期間免費提供電腦錶使用，並指導電腦錶操作，讓潛水過程更加安全。
                </li>
                <li>
                  海洋課程期間提供電腦錶使用，讓潛水過程安全又方便。
                </li>
                <li>
                  課程結訓即日起三個月內，參加東北角岸潛活動享有迎新優惠價，每場次含裝備租借最低價1300元。
                </li>
              </ul>
              <br>
              <img src="../../../../assets/Image/Data/OWD_Marketing_Area_02.jpg" width="60%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
            </div>
            <!-- 平日班 課程規劃 -->
            <div>
              <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td width="10%">
                    </td>
                    <td>
                      <p class="fs-6">
                        DAY 1 | 學科+泳池/平靜水域 08:00~18:00<br>
                      </p>
                      <hr>
                      <p class="fs-6">
                        潛水物理學<br>
                        潛水生理學<br>
                        贈品套量<br>
                        裝備組裝與下水前檢查<br>
                        潛水技巧練習
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    </td>
                    <td>
                      <p class="fs-6">
                        <br>
                        DAY 2 | 學科+平靜/開放水域 09:00~19:00<br>
                      </p>
                      <hr>
                      <p class="fs-6">
                        海洋生物與環境<br>
                        狀況模擬及障礙排除<br>
                        潛水技巧練習<br>
                        裝備清洗
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    </td>
                    <td>
                      <p class="fs-6">
                        <br>DAY 3 | 開放水域 09:00~19:00<br>
                      </p>
                      <hr>
                      <p class="fs-6">
                        潛水技巧複習<br>
                        水中障礙排除<br>
                        水中方向辨認<br>
                        共生練習<br>
                        潛水日誌記錄<br>
                        學科考試
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                    </td>
                    <td>
                      <p class="fs-6">
                        <br>DAY 4 | 開放水域 09:00~16:00<br>
                      </p>
                      <hr>
                      <p class="fs-6">
                        Fun Dive體驗
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br>
              <div id="carouselExampleIndicators" class="carousel slide">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../../../../assets/Image/Data/OWD_Weekdays_Schedule.png" class="d-block w-100" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../../../../assets/Image/Data/OWD_Weekdays_Schedule01.png" class="d-block w-100" alt="...">
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
              <br>

            </div>
            <!-- 平日班 超值學費 -->
            <!-- 最新優惠 -->
            <div><!-- 
              <br>
              <h3 style="font-family:DFKai-sb;">最新優惠</h3>
              <hr>
              <br>
              <div class="d-block grey lighten-3">
                <table borderless="true">
                  <tbody>
                    <tr>
                      <td colspan="3" class="fs-6">
                        <p>
                          最新優惠<br>
                          🌞夏季涼一夏優惠方案🌞<br>
                          現在報名假日班15-19期/平日班12-15期課程<br>
                          🎉優惠2500元（不得與其他方案並用）<br>
                          <br>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
            </div>
            <!-- 方案一  -->
            <div>
              <br>
              <br>
              <h3 style="font-family:DFKai-sb;">課程學費</h3>
              <hr>
              <br>
              <p class="fs-5" style="color: green;">定價 19,800 元（含贈品）</p>
              <ul>
                <li>現金優惠，課程費用付現，可折扣 300 元。</li>
                <li>費用包含：輕裝組、SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（浮力調整裝置、調節器、電腦錶）、旅遊平安保險。</li>
                <li>費用不含：海洋實習交通與用餐費用、浮潛裝備租借。</li>
                <li>
                  自備輕裝 ( 不要贈品 ) 可折扣 3000 元，但自備之裝備需經教練確認安全無虞，才可在課程中使用！<br>
                  註：以上優惠只要符合條件，可合併使用
                </li>
              </ul>
              <p class="fs-6" style="color: red;">團結賺優惠!</p>
              <ul>
                <li>
                  兩人成行，每人折扣 700 元<br>
                  三人同樂，每人折扣 1200 元<br>
                  四五成群，每人折扣 1700 元<br>
                  六人以上請來電洽談。
                </li>
              </ul>
              <p class="fs-6" style="color: red;">老同學秀臉有優惠!</p>
              <ul>
                <li>iDiving 的 自潛Lv1/人魚學員，可再享有1000 元折扣。</li>
              </ul>
              <img src="../../../assets/Image/Data/OWD_Giveaway.png" width="100%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
              <ul>
                <li>
                  贈品:<br>
                  全套總價14,000元。<br>
                  面鏡、呼吸管、防寒衣、潛水手套、套鞋、蛙鞋、網袋
                </li>
              </ul>
            </div>
            <!-- 平日班 報名方式 -->
            <div>
              <br>
              <app-paymentmethod></app-paymentmethod>
              <br>
            </div>
            <!-- 平日班 考核標準 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">考核標準</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" colspan="2">
                      <p>學員結訓均需達到學科與術科課程標準之評鑑</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" width="50px" style="vertical-align:text-top;">
                      <p>學科:</p>
                    </td>
                    <td class="fs-6">
                      <p>需通過筆試測驗（成績達80分以上），未達標準需於檢討後重考。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" width="50px" style="vertical-align:text-top;">
                      <p>術科:</p>
                    </td>
                    <td class="fs-6">
                      <p>平靜水域所學之潛水技巧須在開放水域操作，並接受教練評鑑。</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 平日班 上課地點 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">上課地點</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" colspan="2">
                      <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" width="80px" style="vertical-align:text-top;">
                      <p>教室學科:</p>
                    </td>
                    <td class="fs-6">
                      <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <p>海洋實習:</p>
                    </td>
                    <td class="fs-6">
                      <p>iDiving 東北角龍洞店（新北市貢寮區龍洞街 66-1 號）</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <p>註: </p>
                    </td>
                    <td class="fs-6">
                      <p>
                        上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 平日班 特約課程 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">特約課程</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>學科部份，每另外約一日課程加價 300 元</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>術科部份，每另外約一日課程加價 500 元</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 平日班 更改辦法 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">更改辦法</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>課程須於開始上課後的兩個月內完成。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>上課日期之更改須在 10 天前告知</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>假日班學生可改於平日班完成術科，無額外優惠，亦不須額外付費。</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 平日班 取消辦法 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">取消辦法</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下:</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                    </td>
                    <td class="fs-6">
                      <p>
                        1. NT 3,000 元訂金用做購買線上教材，無法退費。<br>
                        2. 開課前 30 天以上，退回全新贈品，可退費NT 12,000元。<br>
                        3. 開課前 30 天之內，退回全新贈品，可退費NT 10,000元。<br>
                        4. 已上過學科，退回全新贈品，可退費NT 8,000元。<br>
                        5 .已上過泳池課程或贈品已使用，可退費NT 4,000元。<br>
                        6. 已上過海洋實習，恕不退費。
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink>冬季班</a>
          <ng-template ngbNavContent>
            <br>
            <h3 style="font-family:DFKai-sb;">Open Water Diver 冬季班</h3>
            <hr>
            <!-- 冬季班 課程內容 -->
            <div>
              <h3 style="font-family:DFKai-sb;">課程內容</h3>
              <ul>
                <li class="fs-6">
                  學科（9hr）<br>
                  潛水物理學／潛水生理學／裝備介紹／海洋環境／生態介紹／潛伴價值／潛水流程
                </li>
                <li class="fs-6">
                  泳池訓練（4hr）<br>
                  裝備調整／潛水技巧練習／狀況模擬／障礙排除
                </li>
                <li class="fs-6">
                  海洋訓練（12hr）<br>
                  技巧複習／狀況模擬及障礙排除／方向辨認／潛水日誌記錄
                </li>
              </ul>
              <br>
              <img src="../../../../assets/Image/Data/OWD_Marketing_Area_01.jpg" width="100%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
            </div>
            <!-- 冬季班 課程特色 -->
            <div>
              <h3 style="font-family:DFKai-sb;"><br>課程特色</h3>
              <ul class="fs-6">
                <li>
                  SSI數位教材搭配MySSI APP，課前預習、課後潛水日誌記錄，精確掌握學習動態。
                </li>
                <li>
                  合格的領導級教練與學員比例1：2~3。
                </li>
                <li>
                  海洋課程期間免費提供電腦錶使用，並指導電腦錶操作，讓潛水過程更加安全。
                </li>
                <li>
                  海洋課程期間提供電腦錶使用，讓潛水過程安全又方便。
                </li>
                <li>
                  課程結訓即日起三個月內，參加東北角岸潛活動享有迎新優惠價，每場次含裝備租借最低價1300元。
                </li>
              </ul>
              <br>
              <img src="../../../../assets/Image/Data/OWD_Marketing_Area_032.jpg" width="60%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
            </div>
            <!-- 冬季班 課程規劃 -->
            <div>
              <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td>
                      <p class="fs-6">
                        DAY 1 | 學科 + 泳池術科 08:00~18:30<br>
                      </p>
                      <hr>
                      <p class="fs-6">
                        潛水物理學、潛水生理學、贈品套量<br>
                        裝備介紹與組裝練習<br>
                        <br>
                        裝備調整<br>
                        潛水技巧練習<br>
                        狀況模擬及障礙排除
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="fs-6">
                        <br>DAY 2 | 學科 + 泳池術科 08:00~17:30<br>
                      </p>
                      <hr>
                      <p class="fs-6">
                        潛水計畫與執行、潛水電腦錶介紹<br>
                        海洋環境、海洋生物介紹<br>
                        <br>
                        潛水技巧練習<br>
                        狀況模擬及障礙排除
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="fs-6">
                        <br>DAY 3 | 開放水域 09:00~17:00<br>
                      </p>
                      <hr>
                      <p class="fs-6">
                        潛水技巧練習<br>
                        水中障礙排除<br>
                        日誌記錄<br>
                        裝備清洗
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="fs-6">
                        <br>DAY 4 | 開放水域 09:00~14:00<br>
                      </p>
                      <hr>
                      <p class="fs-6">
                        潛水技巧複習<br>
                        狀況模擬及障礙排除<br>
                        水中方向辨認<br>
                        Fun Dive體驗
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p class="fs-6" style="color: red;">
                        <br>
                        註:實際下水時間將依照現場情況調整
                        <br>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br>
              <div id="carouselExampleIndicators" class="carousel slide">
                <div class="carousel-indicators">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                </div>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="../../../../assets/Image/Data/OWD_Winter_Schedule.png" class="d-block w-100" alt="...">
                  </div>
                  <div class="carousel-item">
                    <img src="../../../../assets/Image/Data/OWD_Winter_Schedule01.png" class="d-block w-100" alt="...">
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
              <br>

              <!-- <br>
              <img src="../../../../assets/Image/Data/OWD_Winter_Schedule.webp" width="100%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
              -->
            </div>
            <!-- 冬季班 課程學費 -->
            <!-- 方案一  -->
            <div>
              <br>
              <br>
              <h3 style="font-family:DFKai-sb;">課程學費</h3>
              <br>
              <p class="fs-5" style="color: orange;">定價 19,800 元（含贈品）</p>
              <ul>
                <li>現金優惠，課程費用付現，可折扣 300 元。</li>
                <li>費用包含：輕裝組、SSI數位教材、國際數位證照申請、教練費、訓練期間潛水重裝使用（浮力調整裝置、調節器、電腦錶）、旅遊平安保險。</li>
                <li>費用不含：海洋實習交通與用餐費用、浮潛裝備租借。</li>
                <li>
                  自備輕裝 ( 不要贈品 ) 可折扣 3000 元，但自備之裝備需經教練確認安全無虞，才可在課程中使用！<br>
                  註：以上優惠只要符合條件，可合併使用
                </li>
              </ul>
              <p class="fs-6" style="color: orange;">團結賺優惠!</p>
              <ul>
                <li>
                  兩人成行，每人折扣 700 元<br>
                  三人同樂，每人折扣 1200 元<br>
                  四五成群，每人折扣 1700 元<br>
                  六人以上請來電洽談。
                </li>
              </ul>
              <p class="fs-6" style="color: orange;">老同學秀臉有優惠!</p>
              <ul>
                <li>iDiving 的 自潛Lv1/人魚學員，可再享有1000 元折扣。</li>
              </ul>
              <img src="../../../assets/Image/Data/OWD_Giveaway.png" width="100%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
              <ul>
                <li>
                  贈品:<br>
                  全套總價14,000元。<br>
                  面鏡、呼吸管、防寒衣、潛水手套、套鞋、蛙鞋、網袋
                </li>
              </ul>
            </div>
            <!-- 冬季班 報名方式 -->
            <div>
              <br>
              <app-paymentmethod></app-paymentmethod>
              <br>
            </div>
            <!-- 冬季班 考核標準 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">考核標準</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" colspan="2">
                      <p>學員結訓均需達到學科與術科課程標準之評鑑</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" width="50px" style="vertical-align:text-top;">
                      <p>學科:</p>
                    </td>
                    <td class="fs-6">
                      <p>需通過筆試測驗（成績達80分以上），未達標準需於檢討後重考。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" width="50px" style="vertical-align:text-top;">
                      <p>術科:</p>
                    </td>
                    <td class="fs-6">
                      <p>平靜水域所學之潛水技巧須在開放水域操作，並接受教練評鑑。</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 冬季班 上課地點 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">上課地點</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" colspan="2">
                      <p>上課地點，往返之交通請自行處理，若有需要可以詢問是否能代為安排</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" width="80px" style="vertical-align:text-top;">
                      <p>教室學科:</p>
                    </td>
                    <td class="fs-6">
                      <p>iDiving 士林前港店（台北市士林區前港街 8 號）</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <p>海洋實習:</p>
                    </td>
                    <td class="fs-6">
                      <p>墾丁</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <p>註: </p>
                    </td>
                    <td class="fs-6">
                      <p>
                        上課地點與時間可能視狀況調整（如遇天候不佳或泳池場地整修等），若有額外增加的費用本中心將自行吸收，不會另外收費，但學員不可因課程時間調整而要求退費。 墾丁離島移地訓練產生之費用需自付。
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 冬季班 特約課程 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">特約課程</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>學科部份，每另外約一日課程加價 300 元</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>術科部份，每另外約一日課程加價 500 元</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>三人同行，一起約定特殊時間上課，不須額外加價。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>指定教練，須加價500元/日，如果該名教練已安排其他課程或出團恕無法指定。</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 冬季班 更改辦法 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">更改辦法</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>課程須於開始上課後的兩個月內完成。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>上課日期之更改須在 10 天前告知</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>上課日期 10 天內調整，每次需收取調整費 500 元</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>上課當天通知要更改日期或曠課未通知則需收取調整費 1,000 元</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>因身體不適，出示醫院掛號單據者則不收取。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>女性夥伴參加課程前請自行評估生理期影響。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>假日班學生可改於平日班完成術科，無額外優惠，亦不須額外付費。</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- 冬季班 取消辦法 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">取消辦法</h3>
              <hr>
              <table borderless="true">
                <tbody>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>學員自行放棄課程，本訓練中心將不予退費。</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                      <ul>
                        <li></li>
                      </ul>
                    </td>
                    <td class="fs-6">
                      <p>若因身體因素而不適合從事潛水活動，並開立醫生證明者，繳交之費用退費方式如下:</p>
                    </td>
                  </tr>
                  <tr>
                    <td class="fs-6" style="vertical-align:text-top;">
                    </td>
                    <td class="fs-6">
                      <p>
                        1. NT 3,000 元訂金用做購買線上教材，無法退費。<br>
                        2. 開課前 30 天以上，退回全新贈品，可退費NT 12,000元。<br>
                        3. 開課前 30 天之內，退回全新贈品，可退費NT 10,000元。<br>
                        4. 已上過學科，退回全新贈品，可退費NT 8,000元。<br>
                        5 .已上過泳池課程或贈品已使用，可退費NT 4,000元。<br>
                        6. 已上過海洋實習，恕不退費。
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="4">
          <a ngbNavLink>特約班</a>
          <ng-template ngbNavContent>
            <br>
            <h3 style="font-family:DFKai-sb;">Open Water Diver 特約班</h3>
            <hr>
            <!-- 特約班 課程介紹 -->
            <div>
              <h3 style="font-family:DFKai-sb;">課程內容</h3>
              <ul>
                <li class="fs-6">
                  學科（9hr）<br>
                  潛水物理學／潛水生理學／裝備介紹／海洋環境／生態介紹／潛伴價值／潛水流程
                </li>
                <li class="fs-6">
                  泳池訓練（4hr）<br>
                  裝備調整／潛水技巧練習／狀況模擬／障礙排除
                </li>
                <li class="fs-6">
                  海洋訓練（12hr）<br>
                  技巧複習／狀況模擬及障礙排除／方向辨認／潛水日誌記錄
                </li>
              </ul>
              <br>
              <img src="../../../../assets/Image/Data/OWD_Marketing_Area_01.jpg" width="100%" style="display:block; margin:auto;" alt="潛水 潛水證照 水肺證照 水肺潛水課程 台北學潛水 潛水課程 東北角 龍洞灣 台北水肺潛水課程 水肺潛水" />
            </div>
            <!-- 特約班 課程規劃 -->
            <div>
              <h3 style="font-family:DFKai-sb;"><br>課程規劃</h3>
              <p class="fs-6">
                您的排休無法自行安排又充滿變數嗎？ iDiving 為課程增加額外的彈性，您可以先報名，再客製化您的專屬上課時間！
              </p>
            </div>
            <!-- 特約班 超值學費 -->
            <div>
              <br>
              <h3 style="font-family:DFKai-sb;">課程學費</h3>
              <hr>
              <p class="fs-6" style="color: yellowgreen;">定價 19,800 元（含贈品）</p>
              <p class="fs-6">可搭配固定班，若時間無法配合則可以與 iDiving 另外約定時間上課！平日固定班資訊請參照平日班。</p>
              <p class="fs-6">特殊約定課程費用</p>
              <ul class="fs-6">
                <li>學科部份，每另外約一日課程加價 300 元</li>
                <li>術科部份，每另外約一日課程加價 500 </li>
                <li>三人同行，一起約定特殊時間上課，則不須額外加價。</li>
                <li>指定教練費：指定教練，加價 500 元/日，教練如果已安排既定課程或是出團，恕無法指定。</li>
              </ul>
            </div>
            <!-- 特約班 報名方式 -->
            <div>
              <br>
              <app-paymentmethod></app-paymentmethod>
              <br>
            </div>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>

      <!-- <pre>Active: {{ active }}</pre> -->

    </div>
    <div class="col-xl">
    </div>
  </div>

  <div class="row">
    <table>
      <tr>
        <td>
        </td>
      </tr>
      <tr>
        <td>
        </td>
      </tr>
    </table>
  </div>

  <app-foot></app-foot>

</div>